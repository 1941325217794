export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      users: [],
      roles: [
        'admin',
        'support',
        'sales',
        'developer',
      ],
    };
  },

  actions: {
    // User
    async fetchUsers(params) {
      this.users = [];

      const response = await useApi.get('/users', {
        params,
      }).catch(() => {
        useToast.error(useT('bcd44'));
      });

      if (response) {
        this.users = response.results;
      }

      return response;
    },

    async editUser(id, body) {
      const response = await useApi.patch(`/users/${id}`, {
        body,
      }).catch(() => {
        useToast.error(useT('bcd48'));
      });

      return response;
    },

    async deleteUser(user) {
      const response = await useApi.delete(`/users/${user._id}`).catch(() => {
        useToast.error(useT('bcd50'));
      });

      if (response) {
        _remove(this.users, { id: user._id });

        useToast(useT('bcd51'));
      }

      return response;
    },

    // User/Me
    async updateUserMe(body) {
      const response = await useApi.put('/users/me', { body }).catch(() => {
        useToast.error(useT('bcd52'));
      });

      return response;
    },

    async updateUserMePassword(body) {
      const response = await useApi.put('/users/me/password', { body }).catch((error) => {
        if (error.response.status === 403) {
          useToast.error(useT('bcd53'));
        } else {
          useToast.error(useT('bcd47'));
        }
      });

      return response;
    },

    // User/Invites
    async sendUserInvite(body) {
      const response = await useApi.post('/users/invite', {
        body,
      }).catch((error) => {
        if (error.response?.status === 400) {
          useToast.error(useT('bcd54'));
        } else {
          useToast.error(useT('bcd55'));
        }
      });

      return response;
    },
  },
});
